import * as React from 'react';
import ComponentBase from '../Base/ComponentBase';

const styles = {
	div: {
		padding: "0 2vw",
	} as React.CSSProperties,
};

export class Description extends ComponentBase {
	public constructor(props: Record<string, never>) {
		super(props);
	}

	public render(): React.ReactNode {
		let k = 0;
		const paragraphs = [
			<p key={k++}>
				I received my PhD in <a href="https://engineering.tamu.edu/cse/index.html">Computer Science</a> under the supervision of <a href="http://robotics.cs.tamu.edu/dshell/">Dr. Dylan Shell</a> at <a href="https://tamu.edu/">Texas A&amp;M University</a> in 2024.<br />
				My research interest broadly includes formal specifications, automata theory, applied topology, and computational geometry.
			</p>,
			<p key={k++}>
				My dissertation, titled <b>"A Practical Method of Behavior Estimation in Dynamic Robotic Surveillance Settings"</b>, studies how a robotic system can recognize patterns in movements of a target based on formal specifications given by an end-user.
				Specifically, in cases where a network of autonomous robots equipped with sensors are deployed in a dynamic environment and collect information for a user to determine whether an unpredictable target exhibits certain (normal or anomalous) behavior.
			</p>,
			<p key={k++}>
				I received my MS in 2015 from <a href="https://tamu.edu/">Texas A&amp;M University</a> in Computer Science and my BSc in 2012 from <a href="https://aut.ac.ir/">Amirkabir University of Technology (Tehran Polytechnic)</a> in Computer Engineering.
				My master's thesis is titled <a href="https://oaktrust.library.tamu.edu/handle/1969.1/155694"><b>"Motion Planning for a Tethered Mobile Robot"</b></a>.
				This thesis studies a concise representation of the configuration space of a tethered robot that results in straightforward motion planning algorithms.
			</p>,
			<p key={k++}>
				I have professional experience as a software engineer and manager and
				have worked with many different environments and technology stacks including C++ on both Linux and Windows, .NET, Node.js, Electron, and React.
			</p>,
			<p key={k++}>
				My dog's name is Ashk. Literally <a href="https://translate.google.com/?client=firefox-a&amp;hl=en#view=home&amp;op=translate&amp;sl=auto&amp;tl=en&amp;text=%D8%A7%D8%B4%DA%A9">translated</a> from persian, it means teardrop.
			</p>,
		];
		return <div style={styles.div}>{paragraphs}</div>;
	}
}
